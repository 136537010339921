import { AxiosError } from "axios";
import { FC, MouseEvent, PropsWithChildren, useState } from "react";
import {
  useNotify,
  useRefresh,
  Button as RaButton,
  ButtonProps as RaButtonProps,
  useListSortContext,
  useListContext
} from "react-admin";
import { useQuery } from "react-query";
import { backendUrl } from "../config";
import { stringify } from "qs";
import { ArrowDropDown, Share, Sort } from "@mui/icons-material";
import {
  ButtonGroup,
  ButtonGroupProps,
  ListItemIcon,
  Menu,
  MenuItem
} from "@mui/material";

interface ActionButtonProps extends RaButtonProps {
  id: string;
  action: () => Promise<string>;
}

export const ActionButton: FC<PropsWithChildren<ActionButtonProps>> = ({
  id,
  children,
  action,
  ...props
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { isLoading, refetch } = useQuery(id, action, {
    retry: false,
    enabled: false,
    onSuccess: data => {
      notify(data, { multiLine: true, autoHideDuration: 20000 });
      refresh();
    },
    onError: (error: AxiosError<any>) =>
      notify(error.response?.data?.message || error.message, {
        type: "error",
        multiLine: true,
        autoHideDuration: 20000
      })
  });
  return (
    <RaButton disabled={isLoading} onClick={() => refetch()} {...props}>
      {children}
    </RaButton>
  );
};

interface ActionSplitButtonProps extends ButtonGroupProps {
  id: string;
  options: Array<{ id: string; label: string }>;
  action: () => Promise<string>;
}

export const ActionSplitButton: FC<
  PropsWithChildren<ActionSplitButtonProps>
> = ({ id, children, action, options, ...props }) => {
  const [, setAnchorEl] = useState<Node | null>(null);
  const notify = useNotify();
  const refresh = useRefresh();
  const { isLoading, refetch } = useQuery(id, action, {
    retry: false,
    enabled: false,
    onSuccess: data => {
      notify(data, { multiLine: true, autoHideDuration: 20000 });
      refresh();
    },
    onError: (error: AxiosError<any>) =>
      notify(error.response?.data?.message || error.message, {
        type: "error",
        multiLine: true,
        autoHideDuration: 20000
      })
  });
  return (
    <>
      <ButtonGroup disabled={isLoading} {...props}>
        <RaButton label="Selected" onClick={() => refetch()} />
        <RaButton onClick={e => setAnchorEl(e.currentTarget.parentNode)}>
          <ArrowDropDown />
        </RaButton>
      </ButtonGroup>
    </>
  );
};

interface DownloadButtonProps extends RaButtonProps {
  pathname: string;
  query?: Record<string, any>;
}

export const DownloadButton: FC<PropsWithChildren<DownloadButtonProps>> = ({
  pathname,
  children,
  query = {},
  ...props
}) => {
  function handleClick() {
    window.open(
      `${backendUrl}${pathname}?${stringify({
        companyId: localStorage.getItem("companyId"),
        access_token: localStorage.getItem("token"),
        ...query
      })}`,
      "_blank"
    );
  }
  return (
    <RaButton {...props} onClick={handleClick}>
      {children}
    </RaButton>
  );
};

export const ExportButton = () => {
  const ctx = useListContext();

  return (
    <DownloadButton
      variant="outlined"
      color="primary"
      pathname={`/frontend/${ctx.resource}/export`}
      query={{ filter: ctx.filterValues, ...ctx.sort }}
      label="Export"
    >
      <Share />
    </DownloadButton>
  );
};

interface SortButtonProps extends RaButtonProps {
  fields: Array<[string, string]>;
}

export const SortButton: FC<SortButtonProps> = ({ fields, ...props }) => {
  const { sort, setSort } = useListSortContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (field: string) => {
    setSort({
      field,
      order: sort.field === field && sort.order === "DESC" ? "ASC" : "DESC"
    });
    handleClose();
  };

  const [, selectedField] = fields.find(([name]) => sort.field === name) || [];

  return (
    <>
      <RaButton
        label={selectedField ? selectedField : "Sort"}
        onClick={handleClick}
        {...props}
      >
        <Sort
          style={{ transform: `scaleY(${sort.order === "DESC" ? 1 : -1})` }}
        />
      </RaButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onChange={console.log}
      >
        {fields.map(([field, label]) => (
          <MenuItem
            key={field}
            selected={field === sort.field}
            onClick={() => handleSelect(field)}
          >
            <ListItemIcon>
              <Sort
                style={{
                  transform: `scaleY(${
                    sort.field === field && sort.order === "DESC" ? -1 : 1
                  })`
                }}
              />
            </ListItemIcon>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
