import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  EditProps
} from "react-admin";

export const EditMachine: React.FC<EditProps> = props => (
  <Edit title="Edit Equipment" {...props}>
    <SimpleForm>
      <TextInput disabled source="serialNumber" fullWidth />
      <ReferenceInput
        label="Facility"
        source="facilityId"
        reference="facilities"
      >
        <AutocompleteInput
          filterToQuery={(name: string) => ({ search: { name } })}
          optionText="name"
          fullWidth
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
