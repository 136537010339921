import {
  Pagination as RaPagination,
  TextInput,
  ListBase,
  FilterForm,
  FilterButton,
  CreateButton
} from "react-admin";
import { MobileListGrid } from "../components/mobile-list-grid";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { Circle } from "@mui/icons-material";

export const FacilityList = () => (
  <ListBase
    perPage={50}
    sort={{ field: "updatedAt", order: "DESC" }}
    filterDefaultValues={{ suspended: false }}
  >
    <Toolbar />
    <MobileListGrid
      link={(record: any): any =>
        `/machines?filter=${JSON.stringify({ facilityId: [record.id] })}`
      }
      content={(record: any) => (
        <Stack spacing={2}>
          <Typography variant="body1">{record.name}</Typography>
          <Stack direction={"row"} spacing={1}>
            {record.machines.map((machine: any) => (
              <Chip
                size="small"
                icon={
                  <Circle
                    color={
                      machine.status === "connected" ? "success" : "warning"
                    }
                  />
                }
                variant="outlined"
                label={`${machine.model.manufacturer} ${machine.model.name}`}
              />
            ))}
          </Stack>
        </Stack>
      )}
      metadata={(record: any) => (
        <Typography variant="caption" color={"primary"}>
          {record.organisation?.name}
        </Typography>
      )}
      right={null}
    />
    <Pagination />
  </ListBase>
);

const Pagination = () => (
  <RaPagination rowsPerPageOptions={[10, 25, 50, 100]} perPage={50} />
);

const Toolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
      <FilterForm filters={userFilters} />
      <Box display={"flex"} flexDirection={"row"}>
        <FilterButton filters={userFilters} />
        <CreateButton />
      </Box>
    </Stack>
  );
};

const userFilters = [
  <TextInput label="Name" source="search.name" variant="outlined" />
];
