import React from "react";
import {
  EditButton,
  FieldProps,
  RaRecord,
  ShowBase,
  ShowProps,
  useRecordContext
} from "react-admin";
import { Box, Chip, Stack } from "@mui/material";

import { ShowActions } from "../components/actions";
import { DetailsCard } from "../components/details";

export const MachineDetail: React.FC<ShowProps<RaRecord>> = () => {
  return (
    <ShowBase>
      <Stack direction={"column"} spacing={2}>
        <Actions />
        <Details />
        <Connection />
        <Computer />
      </Stack>
    </ShowBase>
  );
};

const Actions: React.FC = () => {
  const record = useRecordContext();

  return (
    <ShowActions title={`${record?.serialNumber}`}>
      <EditButton variant="contained" />
    </ShowActions>
  );
};

const Details: React.FC<FieldProps<RaRecord>> = () => {
  return (
    <DetailsCard
      title="Details"
      items={machine => [
        {
          label: "",
          value: (
            <Box>
              <Chip
                color={machine.status === "connected" ? "success" : "default"}
                label={machine.status.toUpperCase()}
              />
            </Box>
          )
        },
        { label: "Manufacturer", value: machine.model?.manufacturer },
        { label: "Model", value: machine.model?.name },
        { label: "Serial Number", value: machine.serialNumber }
      ]}
    />
  );
};

const Computer: React.FC<FieldProps<RaRecord>> = () => {
  return (
    <DetailsCard
      title="Computer"
      items={machine => [
        { label: "Host Name", value: machine.computer?.osInfo.hostname },
        { label: "Manufacturer", value: machine.computer?.system.manufacturer }
      ]}
    />
  );
};

const Connection: React.FC<FieldProps<RaRecord>> = () => {
  return (
    <DetailsCard
      title="Connection"
      items={machine =>
        [
          { label: "Method", value: machine.connection.method.toUpperCase() },
          { label: "Path", value: machine.connection.filePath },
          { label: "Host", value: machine.connection.tcpIpHost },
          { label: "Port", value: machine.connection.tcpIpPort },
          { label: "Port", value: machine.connection.serialPort }
        ].filter(d => d.value)
      }
    />
  );
};
