import { BusinessCenter } from "@mui/icons-material";

import { OrgList } from "./list";
import { UserCreate, UserEdit } from "./create-edit";

export const organisations = {
  name: "organisations",
  options: {
    label: "Organisations",
  },
  icon: BusinessCenter,
  list: OrgList,
  create: UserCreate,
  edit: UserEdit,
};
