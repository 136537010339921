import { Computer } from "@mui/icons-material";

import { ComputerList } from "./list";
import { ComputerDetail } from "./show";
import { ComputerEdit } from "./edit";

export const computers = {
  name: "computers",
  options: {
    label: "Computers"
  },
  icon: Computer,
  list: ComputerList,
  show: ComputerDetail,
  edit: ComputerEdit
};
