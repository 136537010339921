import { Box, Breadcrumbs, Typography, Link as MuiLink } from "@mui/material";
import React, { PropsWithChildren } from "react";
import {
  FilterButton,
  Link,
  RaRecord,
  Title,
  TopToolbar,
  useEditContext,
  useShowContext
} from "react-admin";

interface Props {
  title?: string;
  titleField?: string;
}

export const ShowActions: React.FC<PropsWithChildren<Props>> = <
  T extends RaRecord
>({
  title,
  titleField,
  children
}: React.PropsWithChildren<Props>) => {
  const context = useShowContext<T>();

  return (
    <Actions
      title={title || context.record?.[titleField || "id"]}
      resource={context.resource}
      record={context.record}
      mode="show"
    >
      {children}
    </Actions>
  );
};

export const EditActions = <T extends RaRecord>({
  title,
  titleField,
  children
}: React.PropsWithChildren<Props>): React.ReactNode => {
  const context = useEditContext<T>();

  return (
    <Actions
      title={title || context.record?.[titleField || "id"]}
      resource={context.resource}
      record={context.record}
      mode="edit"
    >
      {children}
    </Actions>
  );
};

export const ListActions: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <TopToolbar>
      <FilterButton />
      {children}
    </TopToolbar>
  );
};

const Actions = <T extends RaRecord>({
  title,
  resource,
  record,
  children,
  mode
}: React.PropsWithChildren<{
  title: string;
  resource: string;
  record?: T;
  mode: "edit" | "show" | "list";
}>) => {
  return (
    <TopToolbar style={{ flexBasis: "auto" }}>
      <Title title={[resource.toUpperCase(), title].join(" - ")} />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        flexGrow={1}
        alignItems={"center"}
      >
        <Breadcrumbs>
          <MuiLink underline="none" component={Link} to={`/${resource}`}>
            {resource?.toUpperCase()}
          </MuiLink>
          {mode === "edit"
            ? [
                <MuiLink
                  key={1}
                  underline="none"
                  component={Link}
                  to={`/${resource}/${record?.id}/show`}
                >
                  {title}
                </MuiLink>,
                <Typography variant="body1" key={2}>
                  Edit
                </Typography>
              ]
            : [
                <Typography variant="body1" key={3}>
                  {title}
                </Typography>
              ]}
        </Breadcrumbs>
        {children}
      </Box>
    </TopToolbar>
  );
};
