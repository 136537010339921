import {
  Pagination as RaPagination,
  ListBase,
  FilterForm,
  FilterButton,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import { Box, Chip, Stack, Typography } from "@mui/material";
import moment from "moment";

import { MobileListGrid } from "../components/mobile-list-grid";
import { Conditionally } from "../components/conditionally";
import { ComputerIndicactor } from "./components";

export const ComputerList = () => (
  <ListBase perPage={50} sort={{ field: "updatedAt", order: "DESC" }}>
    <Toolbar />
    <MobileListGrid
      link={(record: any): any => `/computers/${record.id}/show`}
      content={(record: any) => (
        <>
          <Typography variant="body1">
            {record.osInfo.hostname?.toUpperCase()}
          </Typography>
          <Conditionally
            when={Boolean(record.facility?.name)}
            render={() => (
              <Typography color="primary" variant="caption">
                {record.facility?.name}
              </Typography>
            )}
          />
        </>
      )}
      metadata={(record: any) => (
        <Stack direction={"row"} spacing={1}>
          <ComputerIndicactor record={record} />

          <Chip
            size="small"
            variant="outlined"
            label={record.system.manufacturer}
          />

          <Chip
            size="small"
            variant="outlined"
            label={record.osInfo.platform}
          />

          <Conditionally
            when={record.version}
            render={() => (
              <Chip size="small" variant="outlined" label={record.version} />
            )}
          />

          <Chip
            size="small"
            variant="outlined"
            label={`First seen ${moment(record.createdAt).fromNow()}`}
          />
        </Stack>
      )}
      right={null}
    />
    <RaPagination rowsPerPageOptions={[10, 25, 50, 100]} />
  </ListBase>
);

const Toolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
      <FilterForm filters={userFilters} />
      <Box display={"flex"} flexDirection={"row"}>
        <FilterButton filters={userFilters} />
      </Box>
    </Stack>
  );
};

const userFilters: React.ReactNode[] = [
  <ReferenceInput
    label="Facility"
    source="facilityId"
    reference="facilities"
    key={1}
  >
    <AutocompleteInput
      filterToQuery={(name: string) => ({ search: { name } })}
      optionText="name"
      fullWidth
    />
  </ReferenceInput>
];
