import { Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { path } from "ramda";
import { FieldProps, useRecordContext } from "react-admin";

export const TimeField = ({ source }: FieldProps) => {
  const record = useRecordContext();
  if (!record || !source) {
    return null;
  }

  const value = path(source.split("."), record) as any;
  return (
    <Tooltip title={moment(value).toLocaleString()}>
      <Typography variant="body1" textAlign={"center"}>
        {moment(value).fromNow(true)}
      </Typography>
    </Tooltip>
  );
};
