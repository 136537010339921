import { LayoutProps, Menu } from "react-admin";
import { Layout } from "react-admin";

export const MyLayout: React.FC<LayoutProps> = props => (
  <Layout {...props} menu={MyMenu} />
);

export const MyMenu = () => {
  return (
    <Menu>
      <Menu.ResourceItems />
    </Menu>
  );
};
