import { Chat } from "@mui/icons-material";

import { MessageList } from "./list";

export const messages = {
  name: "messages",
  options: {
    label: "Message"
  },
  icon: Chat,
  list: MessageList
};
