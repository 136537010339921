import { mergeAll } from "ramda";

export type Role =
  | "super-admin"
  | "admin"
  | "organisation"
  | "facility"
  | "vendor";

export const superAdminRole: Role = "super-admin";
export const adminRole: Role = "admin";
export const organisationRole: Role = "organisation";
export const facilityRole: Role = "facility";
export const vendorRole: Role = "vendor";

export const roles: Array<{ id: Role; name: string }> = [
  {
    id: superAdminRole,
    name: "System Developer"
  },
  {
    id: adminRole,
    name: "System Administrator"
  },
  {
    id: organisationRole,
    name: "Organisation User"
  },
  {
    id: facilityRole,
    name: "Facility User"
  },
  {
    id: vendorRole,
    name: "Vendor User"
  }
];
export const roleMapper: Record<string, string> = mergeAll(
  roles.map(role => ({
    [role.id]: role.name
  }))
);
