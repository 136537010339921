import {
  Pagination,
  ListBase,
  FilterForm,
  FilterButton,
  Datagrid,
  TextField,
  FunctionField,
  FieldProps,
  useRecordContext,
  RaRecord,
  ReferenceArrayInput,
  AutocompleteArrayInput
} from "react-admin";
import { MobileListGrid } from "../components/mobile-list-grid";
import {
  Box,
  Chip,
  ChipProps,
  Stack,
  Theme,
  Typography,
  useMediaQuery
} from "@mui/material";
import moment from "moment";
import { Conditionally } from "../components/conditionally";
import { Public, PublicOff } from "@mui/icons-material";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { TimeField } from "../components/fields";

export const MachineList = () => {
  const isSmall = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down("sm"),
    {
      noSsr: true
    }
  );

  return (
    <ListBase
      perPage={50}
      sort={{ field: "computer.updatedAt", order: "DESC" }}
      filterDefaultValues={{ suspended: false }}
    >
      <Toolbar />
      <Conditionally
        when={!isSmall}
        render={() => (
          <Datagrid rowClick="show">
            <StatusField size="medium" />
            <FunctionField
              source="model.name"
              label="Model"
              sortable={false}
              render={(record: RaRecord) => (
                <Typography variant="body1">
                  {record?.model?.manufacturer} {record?.model?.name}
                </Typography>
              )}
            />
            <TextField source="serialNumber" sortable={false} />
            <TextField source="facility.name" sortable={false} />
            <TimeField source="computer.updatedAt" label="Last Online" />
            <FunctionField
              label="Activity"
              render={(record: any) => (
                <Conditionally
                  when={record.sparklines?.some(Boolean)}
                  render={() => (
                    <Sparklines
                      data={record.sparklines}
                      // limit={14}
                      min={0}
                      style={{ height: 48, display: "block" }}
                    >
                      <SparklinesLine style={{ fill: "green" }} />
                    </Sparklines>
                  )}
                />
              )}
            />
          </Datagrid>
        )}
      />

      <Conditionally
        when={isSmall}
        render={() => (
          <MobileListGrid
            link={(record: any): any => `/machines/${record.id}/show`}
            content={(record: any) => (
              <Box>
                <Typography variant="body1">
                  {record.model.manufacturer} {record.model.name}
                </Typography>

                <Conditionally
                  when={Boolean(record.facility?.name)}
                  render={() => (
                    <Typography color="primary" variant="caption">
                      {record.facility?.name}
                    </Typography>
                  )}
                />
              </Box>
            )}
            metadata={(record: any) => (
              <Stack direction={"row"} spacing={1}>
                <Chip
                  size="small"
                  variant="filled"
                  color={record.status === "connected" ? "success" : "warning"}
                  label={moment(record.updatedAt).fromNow(true)}
                />
                <Chip
                  size="small"
                  variant="outlined"
                  label={record.serialNumber}
                />
                <Chip
                  size="small"
                  variant="outlined"
                  icon={
                    moment()
                      .subtract(8, "hours")
                      .isBefore(record.computer?.updatedAt) ? (
                      <Public color="success" />
                    ) : (
                      <PublicOff color="disabled" />
                    )
                  }
                  label={moment(record.computer?.updatedAt).fromNow(true)}
                />
              </Stack>
            )}
            right={(record: any) => (
              <Stack direction={"column"} alignItems={"flex-end"}>
                <Conditionally
                  when={record.sparklines?.some(Boolean)}
                  render={() => (
                    <Sparklines
                      data={record.sparklines}
                      // limit={14}
                      min={0}
                      style={{ height: 48, display: "block" }}
                    >
                      <SparklinesLine style={{ fill: "green" }} />
                    </Sparklines>
                  )}
                />
              </Stack>
            )}
          />
        )}
      />

      <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
    </ListBase>
  );
};

const Toolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
      <FilterForm filters={userFilters} />
      <Box display={"flex"} flexDirection={"row"}>
        <FilterButton filters={userFilters} />
      </Box>
    </Stack>
  );
};

const userFilters: React.ReactNode[] = [
  <ReferenceArrayInput
    key={0}
    label="Model"
    reference="models"
    source="modelId"
  >
    <AutocompleteArrayInput
      filterToQuery={(q: string) => ({
        search: { manufacturer: q }
      })}
      optionText={option => `${option.manufacturer} ${option.name}`}
    />
  </ReferenceArrayInput>,

  <ReferenceArrayInput
    label="Facility"
    source="facilityId"
    reference="facilities"
    key={1}
  >
    <AutocompleteArrayInput
      filterToQuery={(name: string) => ({ search: { name } })}
      optionText="name"
      fullWidth
    />
  </ReferenceArrayInput>
];

const StatusField = ({ size = "small" }: FieldProps & ChipProps) => {
  const record = useRecordContext();
  return (
    <Chip
      size={size}
      variant="filled"
      color={record?.status === "connected" ? "success" : "warning"}
      label={`${record?.status} ${moment(record?.updatedAt).fromNow(true)}`}
    />
  );
};
