import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  GridProps,
  Paper,
  Typography
} from "@mui/material";
import React from "react";
import { RaRecord, useRecordContext } from "react-admin";

interface Item extends Pick<GridProps, "xs" | "md" | "sm" | "xl" | "lg"> {
  label?: string;
  value: React.ReactNode;
  color?: string;
}

interface Props<Record extends RaRecord> {
  title: string;
  items(record: Record): Item[];
  actions?: (record: Record) => React.ReactNode;
}

export const DetailsCard: React.FC<Props<RaRecord>> = ({
  title,
  items,
  actions
}: Props<RaRecord>) => {
  const record = useRecordContext<RaRecord>();

  if (!record) {
    return null;
  }

  return (
    <Card elevation={4}>
      <CardHeader title={title} />
      <CardContent>
        <Grid container spacing={2}>
          {items(record).map(({ value, label, ...item }, i) => (
            <Grid key={i} item {...{ xs: 6, sm: 4, md: 3, ...item }}>
              {label && (
                <Typography variant="caption" color="primary">
                  {label}
                </Typography>
              )}
              {typeof value !== "object" ? (
                <Typography variant="body1">{value || "-"}</Typography>
              ) : (
                value
              )}
            </Grid>
          ))}
        </Grid>
      </CardContent>
      {actions ? <CardActions>{actions(record)}</CardActions> : null}
    </Card>
  );
};

interface GenericDetailsCardProps<Record> {
  title: string;
  items(record: Record): Item[];
  actions?: (record: Record) => React.ReactNode;
  record: Record;
}

export const GenericDetailsCard = <T extends Record<any, any>>({
  items,
  actions,
  record
}: GenericDetailsCardProps<T>): React.ReactNode => {
  if (!record) {
    return;
  }

  return (
    <Paper elevation={4} component={Box} padding={1}>
      <Box padding={1}>
        <KeyValueGrid items={items(record)} />
      </Box>

      {actions?.(record)}
    </Paper>
  );
};

export const KeyValueGrid: React.FC<{ items: Item[] }> = ({ items }) => {
  return (
    <Grid container spacing={1}>
      {items.map(({ label, value, ...item }, i) => (
        <Grid
          key={i}
          item
          {...(item.color ? { style: { background: item.color } } : {})}
          {...{ xs: 6, sm: 4, md: 3, ...item }}
        >
          {label && (
            <Typography variant="caption" color="primary">
              {label}
            </Typography>
          )}
          {typeof value !== "object" ? (
            <Typography variant="caption" display={"block"}>
              {value || "-"}
            </Typography>
          ) : (
            value
          )}
        </Grid>
      ))}
    </Grid>
  );
};
