import { Router } from "@mui/icons-material";
import { MachineList } from "./list";
import { MachineDetail } from "./show";
import { EditMachine } from "./edit";

export const machines = {
  name: "machines",
  options: {
    label: "Equipment"
  },
  icon: Router,
  list: MachineList,
  show: MachineDetail,
  edit: EditMachine
};

export const machineModels = {
  name: "models"
};

export const machineManufacturers = {
  name: "models/manufacturers"
};
