import {
  AutocompleteInput,
  Create,
  Edit,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required
} from "react-admin";

export const FacilityCreate = () => {
  return (
    <Create title={"Register Facility"}>
      <Form />
    </Create>
  );
};

export const FacilityEdit = () => {
  return (
    <Edit title="Edit Facility">
      <Form />
    </Edit>
  );
};

export const Form = ({ ...props }) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" fullWidth validate={[required()]} />

      <ReferenceInput
        label="Organisation"
        reference="organisations"
        source="organisationId"
      >
        <AutocompleteInput
          filterToQuery={(name: string) => ({ search: { name } })}
          optionText={"name"}
          fullWidth
        />
      </ReferenceInput>
    </SimpleForm>
  );
};
