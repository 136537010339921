import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  CheckboxGroupInput,
  usePermissions,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput
} from "react-admin";
import { useWatch } from "react-hook-form";

import {
  adminRole,
  facilityRole,
  organisationRole,
  roles,
  superAdminRole,
  vendorRole
} from "../roles";
import { userPermissions } from "./constants";
import { Conditionally } from "../components/conditionally";

export const UserCreate = () => {
  return (
    <Create title={"Register User"}>
      <SimpleForm>
        <Form />
      </SimpleForm>
    </Create>
  );
};

export const UserEdit = () => {
  return (
    <Edit title="Edit User">
      <SimpleForm>
        <Form />
      </SimpleForm>
    </Edit>
  );
};

export const Form = () => {
  const form = useWatch();
  const { permissions: user } = usePermissions();
  return (
    <>
      <TextInput source="name" fullWidth validate={[required()]} />

      <TextInput source="email" fullWidth validate={[required()]} />

      <TextInput source="phone" fullWidth />

      <SelectInput
        source="role"
        choices={roles.filter(
          role =>
            (user.role === organisationRole &&
              ![superAdminRole, adminRole, vendorRole].includes(role.id)) ||
            (user.role === facilityRole && [facilityRole].includes(role.id)) ||
            (user.role === vendorRole && [vendorRole].includes(role.id)) ||
            (user.role === adminRole && role.id !== superAdminRole) ||
            true
        )}
        fullWidth
        validate={[required()]}
      />

      <Conditionally
        when={form.role === organisationRole}
        render={() => (
          <ReferenceInput
            label="Organisation"
            reference="organisations"
            source="organisationId"
          >
            <AutocompleteInput
              filterToQuery={(name: string) => ({ search: { name } })}
              optionText={"name"}
              fullWidth
            />
          </ReferenceInput>
        )}
      />

      <Conditionally
        when={form.role === facilityRole}
        render={() => (
          <ReferenceInput
            label="Facility"
            reference="facilities"
            source="facilityId"
          >
            <AutocompleteInput
              filterToQuery={(name: string) => ({ search: { name } })}
              optionText={"name"}
              fullWidth
            />
          </ReferenceInput>
        )}
      />

      <Conditionally
        when={form.role === vendorRole}
        render={() => (
          <ReferenceArrayInput
            label="Manufacturer"
            reference="models/manufacturers"
            source="manufacturers"
          >
            <AutocompleteArrayInput
              filterToQuery={(name: string) => ({ search: { name } })}
              optionText={"name"}
              fullWidth
            />
          </ReferenceArrayInput>
        )}
      />

      <Conditionally
        when={user?.permissions?.user?.includes("suspend")}
        render={() => <BooleanInput source="suspended" />}
      />

      <CheckboxGroupInput
        source="permissions.user"
        label="User Permissions"
        choices={userPermissions}
      />
    </>
  );
};
