import { List } from "@mui/icons-material";
import { ResultList } from "./list";
import { MachineDetail } from "./show";

export const results = {
  name: "results",
  options: {
    label: "Results"
  },
  icon: List,
  list: ResultList,
  show: MachineDetail,
};
