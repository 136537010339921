import { Box, Checkbox, Grid, List, ListItem, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import { Conditionally } from "./conditionally";
import { WithListContext } from "react-admin";
import { pluck } from "ramda";

export const MobileListGrid = ({
  metadata,
  content,
  right,
  link,
  onClick = null,
  hasBulkActions = false,
  onSelect = null,
  selectedIds = []
}) => (
  <WithListContext
    render={({ data = [] }) => (
      <List>
        <Conditionally
          when={hasBulkActions}
          render={() => (
            <ListItem component={Box}>
              <Checkbox
                checked={selectedIds.length === data.length}
                onChange={e =>
                  onSelect(e.target.checked ? pluck("id", data) : [])
                }
              />
            </ListItem>
          )}
        />
        {data.map(record => {
          return (
            <ListItem
              component={Paper}
              elevation={3}
              variant="outlined"
              square
              display="flex"
              alignItems="center"
              key={record.id}
            >
              <Conditionally
                when={hasBulkActions}
                render={() => (
                  <Checkbox
                    checked={selectedIds.includes(record.id)}
                    onChange={e =>
                      onSelect(
                        e.target.checked
                          ? [...selectedIds, record.id]
                          : selectedIds.filter(x => x !== record.id)
                      )
                    }
                  />
                )}
              />

              <Box
                {...(link ? { component: Link, to: link(record) } : {})}
                {...(onClick ? { onClick: () => onClick(record) } : {})}
                padding={1}
                flexGrow={1}
                color="inherit"
              >
                <Grid container direction="column" spacing={1}>
                  {metadata && <Grid item>{metadata(record)}</Grid>}
                  <Grid item>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      wrap="nowrap"
                    >
                      {content && <Grid item>{content(record)}</Grid>}
                      {right && <Grid item>{right(record)}</Grid>}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </ListItem>
          );
        })}
      </List>
    )}
  />
);
