import { Pagination, ListBase, FilterForm, FilterButton } from "react-admin";
import { MobileListGrid } from "../components/mobile-list-grid";
import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import { Conditionally } from "../components/conditionally";
import { SortButton } from "../components/action-button";

export const ResultList = () => (
  <ListBase
    perPage={50}
    sort={{ field: "completedAt", order: "DESC" }}
  >
    <Toolbar />
    <ResultListGrid />
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
  </ListBase>
);

const Toolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
      <FilterForm filters={filters} />
      <Box display={"flex"} flexDirection={"row"}>
        <SortButton
          fields={[
            ["completedAt", "Completed"],
            ["updatedAt", "Uploaded"]
          ]}
        />
        <FilterButton filters={filters} />
      </Box>
    </Stack>
  );
};

export const ResultListGrid = () => (
  <MobileListGrid
    link={null}
    right={null}
    // link={(record: any): any => `/results/${record.id}/show`}
    content={(record: any) => (
      <Stack direction={"column"} spacing={1}>
        <Typography variant="body1">{record.sample?.id}</Typography>
        <Grid
          container
          direction={"row"}
          spacing={1}
          flexWrap={"wrap"}
          alignItems={"flex-end"}
        >
          {record.results?.slice(0, 10).map((parameter: any) => (
            <Grid item component={Stack} direction={"column"}>
              <Typography variant="caption">{parameter.name}</Typography>
              <Chip
                size="small"
                color={
                  parameter.flag === "N"
                    ? "success"
                    : parameter.flag
                    ? "error"
                    : "default"
                }
                label={[
                  parameter.value,
                  parameter.unit,
                  parameter.flag ? ` (${parameter.flag})` : null
                ]
                  .filter(Boolean)
                  .join("")}
              />
            </Grid>
          ))}
          <Conditionally
            when={record.results?.slice(10).length > 0}
            render={() => (
              <Grid item>
                <Chip
                  size="small"
                  label={`+${record.results?.slice(10).length} More`}
                />
              </Grid>
            )}
          />
        </Grid>
      </Stack>
    )}
    metadata={(record: any) =>
      record && (
        <Stack direction={"row"} spacing={1}>
          <Chip
            size="small"
            variant="outlined"
            label={`Uploaded ${moment(record.updatedAt).fromNow(true)}`}
          />
          <Conditionally
            when={Boolean(record.machine)}
            render={() => (
              <Chip
                size="small"
                variant="outlined"
                label={`${record?.machine?.model?.manufacturer} ${record?.machine?.model?.name}`}
              />
            )}
          />
          <Conditionally
            when={Boolean(record.operator)}
            render={() => (
              <Chip size="small" variant="outlined" label={record.operator} />
            )}
          />

          <Conditionally
            when={record.completedAt}
            render={() => (
              <Chip
                size="small"
                variant="outlined"
                label={`Completed ${moment(record.completedAt).fromNow()}`}
              />
            )}
          />
        </Stack>
      )
    }
  />
);

const filters: React.ReactNode[] = [];
