import { LocalHospital } from "@mui/icons-material";

import { FacilityList } from "./list";
import { FacilityCreate, FacilityEdit } from "./create-edit";

export const facilities = {
  name: "facilities",
  options: { label: "Facilities" },
  icon: LocalHospital,
  list: FacilityList,
  create: FacilityCreate,
  edit: FacilityEdit
};
