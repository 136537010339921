import {
  Pagination as RaPagination,
  ListBase,
  FilterForm,
  FilterButton,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import { Box, Stack, Typography } from "@mui/material";

import { MobileListGrid } from "../components/mobile-list-grid";
import { TimeField } from "../components/fields";

export const MessageList = () => (
  <ListBase perPage={50} sort={{ field: "updatedAt", order: "DESC" }}>
    <Toolbar />
    <MobileListGrid
      link={(record: any): any => `/computers/${record.id}/show`}
      content={(record: any) => (
        <>
          <Typography>{record.hash.slice(-6)}</Typography>
          <Typography>{record.machine?.model?.name}</Typography>
        </>
      )}
      metadata={(record: any) => null}
      right={(record: any) => <TimeField record={record} source="updatedAt" />}
    />
    <RaPagination rowsPerPageOptions={[10, 25, 50, 100]} />
  </ListBase>
);

const Toolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
      <FilterForm filters={userFilters} />
      <Box display={"flex"} flexDirection={"row"}>
        <FilterButton filters={userFilters} />
      </Box>
    </Stack>
  );
};

const userFilters: React.ReactNode[] = [
  <ReferenceInput
    label="Facility"
    source="facilityId"
    reference="facilities"
    key={1}
  >
    <AutocompleteInput
      filterToQuery={(name: string) => ({ search: { name } })}
      optionText="name"
      fullWidth
    />
  </ReferenceInput>
];
