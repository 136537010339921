import { FiberManualRecord } from "@mui/icons-material";
import { Chip } from "@mui/material";
import moment from "moment";
import React from "react";
import { RaRecord } from "react-admin";

export const ComputerIndicactor: React.FC<{ record: RaRecord }> = ({
  record
}) => {
  return moment().diff(record?.updatedAt, "minute") < 60 ? (
    <Chip
      size="small"
      variant="outlined"
      icon={<FiberManualRecord color={"success"} />}
      label={`Online ${moment(record?.updatedAt).fromNow(true)}`}
    />
  ) : (
    <Chip
      size="small"
      variant="outlined"
      icon={<FiberManualRecord color={"error"} />}
      label={`Offline ${moment(record.updatedAt).fromNow(true)}`}
    />
  );
};
