import React from "react";
import {
  EditButton,
  FieldProps,
  RaRecord,
  ShowBase,
  ShowProps,
  useRecordContext
} from "react-admin";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Stack,
  Typography
} from "@mui/material";

import { ShowActions } from "../components/actions";
import { DetailsCard } from "../components/details";
import { Link } from "react-router-dom";

export const ComputerDetail: React.FC<ShowProps<RaRecord>> = () => {
  return (
    <ShowBase>
      <Stack direction={"column"} spacing={2}>
        <Actions />
        <Details />
        <Machines />
        <Networks />
        <Disks />
      </Stack>
    </ShowBase>
  );
};

const Actions: React.FC = () => {
  const record = useRecordContext();
  
  if (!record) {
    return null;
  }

  return (
    <ShowActions title={`${record.osInfo.hostname}`}>
      <EditButton variant="contained" />
    </ShowActions>
  );
};

const Details: React.FC<FieldProps<RaRecord>> = () => {
  return (
    <DetailsCard
      title="Details"
      items={machine =>
        machine && [
          { label: "Manufacturer", value: machine.system?.manufacturer },
          { label: "Model", value: machine.system?.model },
          { label: "Serial Number", value: machine.system.serial },
          { label: "Hostname", value: machine.osInfo?.hostname },
          { label: "OS", value: machine.osInfo?.distro },
          { label: "OS Version", value: machine.osInfo?.release },
          { label: "Timezone", value: machine.timezone },
          { label: "Country", value: machine.location?.country },
          { label: "Region", value: machine.location?.region },
          { label: "City", value: machine.location?.city },
          { label: "Version", value: machine.version },
          {
            label: "Users",
            value: machine.users?.map?.((user: any) => user.user)?.join(", ")
          }
        ]
      }
    />
  );
};

const Machines: React.FC<FieldProps<RaRecord>> = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <Card>
      <CardHeader title="Machines" />
      <CardContent>
        <Grid container spacing={2} flexWrap={"wrap"}>
          {record?.machines?.map?.((machine: any) => (
            <Grid item xs={6} sm={4} md={3}>
              <Link to={`/machines/${machine.id}/show`}>
                <Paper component={Box} padding={2} variant="outlined">
                  <Typography variant="caption">
                    {machine.model.manufacturer} {machine.model.name}
                  </Typography>
                  <Typography variant="body1">
                    {machine.serialNumber}
                  </Typography>
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

const Networks: React.FC<FieldProps<RaRecord>> = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <Card>
      <CardHeader title="Networks" />
      <CardContent>
        <Grid container spacing={2} flexWrap={"wrap"}>
          {record.networkInterfaces?.map?.((network: any) => (
            <Grid item xs={6} sm={4} md={3}>
              <Paper component={Box} padding={2} variant="outlined">
                <Typography variant="caption">{network.iface}</Typography>
                <Typography variant="body1">
                  {network.ip4 || network.ip6}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

const Disks: React.FC<FieldProps<RaRecord>> = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <Card>
      <CardHeader title="Disks" />
      <CardContent>
        <Stack direction={"row"} spacing={2}>
          {record.diskLayout?.map?.((disk: any) => (
            <Paper component={Box} padding={2} variant="outlined">
              <Typography>{disk.name}</Typography>
              <Typography>{toGb(disk.size)}</Typography>
            </Paper>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

function toGb(size: number) {
  return (size / Math.pow(1024, 3)).toFixed(2) + "GB";
}
