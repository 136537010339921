import { Create, Edit, SimpleForm, TextInput, required } from "react-admin";

export const UserCreate = () => {
  return (
    <Create title={"Register Organisation"}>
      <Form />
    </Create>
  );
};

export const UserEdit = () => {
  return (
    <Edit title="Edit Organisation">
      <Form />
    </Edit>
  );
};

export const Form = ({ ...props }) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" fullWidth validate={[required()]} />
    </SimpleForm>
  );
};
