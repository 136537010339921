import { SupervisorAccount } from "@mui/icons-material";

import { UserList } from "./list";
import { UserCreate, UserEdit } from "./create-edit";

export const users = {
  name: "users",
  options: {
    label: "Users",
  },
  icon: SupervisorAccount,
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
};

export const roles = {
  name: "users/roles",
};
